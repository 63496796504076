import Vue from 'vue';
import VeeValidate from 'vee-validate';
import * as Sentry from '@sentry/browser';
import * as SentryIntegrations from '@sentry/integrations';
import { sync } from 'vuex-router-sync';
import moment from 'moment';
import VueMomentJS from 'vue-momentjs';
// import VueAnalytics from 'vue-analytics';
import VueIntercom from 'vue-intercom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'unfetch/polyfill/index';

import VueYieldify from '@/yieldify';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import filters from '@/filters';
import globalMixin from '@/mixins/global';
import { globalStore as global } from '@/store/global';
import busPlugin, { bus } from '@/plugins/bus';
import vuetify from '@/plugins/vuetify/vuetify';
import '@/plugins/class-component-hooks';
import { ACTION_SET_STARDUST_API } from '@/store/api';

function inIframe() {
  try {
    return window.frameElement && window.self !== window.top;
  } catch (e) {
    return true;
  }
}

if (!inIframe()) {
  fetch('/v2/public-config.json')
    .then(response => response.json())
    .then((data) => {
      // Vue.use(VueAnalytics, {
      //   id: data.UI_GOOGLE_ANALYTICS_ID,
      //   router,
      // });

      if (data.INTERCOM_ID !== undefined && data.INTERCOM_ID !== '') {
        Vue.use(VueIntercom, {
          appId: data.INTERCOM_ID,
        });
        global.intercom_enabled = true;
      }

      if (data.UI_YIELDIFY_PROXY_HOST && data.UI_YIELDIFY_PROXY_HOST !== '') {
        global.yieldify_proxy_host = data.UI_YIELDIFY_PROXY_HOST;
      }

      if (data.UI_YIELDIFY_ENABLED && data.UI_YIELDIFY_ID) {
        Vue.use(VueYieldify, {
          id: data.UI_YIELDIFY_ID,
        });
      }

      window.addEventListener('storage', (e) => {
        if (e.key === 'Token' && !e.oldValue && e.newValue) {
          location.hash = '/';
        }
      });

      document.addEventListener('scroll', () => {
        const footerContentEl: null | HTMLDivElement = document.querySelector('.footer-content');
        const footerHeight = footerContentEl ? footerContentEl.offsetHeight : 55;
        const documentHeight = document.body.clientHeight;
        const currentScrollPosition = window.scrollY + window.innerHeight;
        const intercomButton: null | HTMLDivElement = document.querySelector('.intercom-launcher-frame');

        if (!intercomButton) {
          return;
        }

        intercomButton.style.bottom = currentScrollPosition >= documentHeight - footerHeight / 2 - 6
          ? `${currentScrollPosition - documentHeight + footerHeight - 6}px`
          : '20px';
      });

      if (data.UI_SENTRY_DSN !== undefined
        && data.UI_SENTRY_DSN !== '') {
        Sentry.init({
          dsn: data.UI_SENTRY_DSN,
          integrations: [
            new SentryIntegrations.Vue({
              Vue,
              attachProps: true,
            }),
          ],
          beforeSend(event) {
            return event;
          },
        });

        // Define a new console
        const console = (function (oldCons) {
          return {
            log(text: any) {
              oldCons.log(text);
            },
            info(text: any) {
              oldCons.info(text);
              Sentry.configureScope((scope) => {
                scope.setTag('type', 'info');
              });
              Sentry.captureMessage(`Info: ${text}`);
            },
            warn(text: any) {
              oldCons.warn(text);
              Sentry.configureScope((scope) => {
                scope.setTag('type', 'warn');
              });
              Sentry.captureMessage(`Warning: ${text}`);
            },
            error(text: any) {
              oldCons.error(text);
              Sentry.configureScope((scope) => {
                scope.setTag('type', 'error');
              });
              Sentry.captureMessage(`Error: ${text}`);
            },
            debug(text: any) {
              oldCons.debug(text);
            },
          };
        }(window.console));

        // Then redefine the old console
        // @ts-ignore
        window.console = console;
      }
      return Promise.resolve(data);
    })
    .then(data => store.commit('global/replacePublicConfig', data))
    .then(() => store.dispatch(ACTION_SET_STARDUST_API))
    .then(() => {
      Vue.config.productionTip = false;

      Vue.config.devtools = true;
      sync(store, router);

      Vue.use(VeeValidate, {
        fieldsBagName: 'veeFields',
      } as any);
      Vue.use(VueMomentJS, moment);
      Vue.use(busPlugin);

      Vue.filter('slice', filters.slice);
      Vue.filter('moment', filters.moment);
      Vue.filter('fromNow', filters.fromNow);
      Vue.filter('splitText', filters.splitText);
      Vue.filter('toCurrency', filters.toCurrency);
      Vue.filter('featureToString', filters.featureToString);
      Vue.filter('uppercase', filters.uppercase);
      Vue.filter('kebabCase', filters.kebabCase);
      Vue.filter('formatSpeed', filters.formatSpeed);
      Vue.mixin(globalMixin);

      /* eslint-disable no-new */
      new Vue({
        el: '#app',
        router,
        store,
        // @ts-ignore
        bus,
        vuetify,
        // mixins: [general],
        template: '<App/>',
        components: {
          App,
        },
      });
    });
}
